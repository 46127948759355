import { Stack, Typography, Box } from '@mui/material';
import React from 'react';
import CustomButton from 'components/button/BasicButton';
import { colors } from 'constants/colors';
import { useNavigate } from 'react-router-dom';
import IconImage from './IconImage';
import coinImage from 'assets/images/coin.png'; // 이미지 경로
import scaleImage from 'assets/images/scale.png';
import billImage from 'assets/images/bill.png';
import {
	LineChart,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	Line,
	ResponsiveContainer,
	Legend,
} from 'recharts';
const efficientFrontierData = [
	{
		expectedReturn: 0.020487229772487882,
		volatility: 0.12085421892432732,
		weights: [0.4, 0.2132348447712306, 0.05, 0.3367651552287695],
	},
	{
		expectedReturn: 0.02055234328772989,
		volatility: 0.12085426344263944,
		weights: [
			0.4000008344916364, 0.2155681097863008, 0.0499959772978396,
			0.3344350884776067,
		],
	},
	{
		expectedReturn: 0.020618191800984006,
		volatility: 0.12085601105294842,
		weights: [
			0.4000010415875444, 0.2179261808832807, 0.0499957741084388,
			0.3320770122192744,
		],
	},
	{
		expectedReturn: 0.0206839575189601,
		volatility: 0.12085899776767026,
		weights: [
			0.4000010429632767, 0.2202812838262215, 0.0499957780824872,
			0.3297219038892382,
		],
	},
	{
		expectedReturn: 0.02074972090921403,
		volatility: 0.12086318300617879,
		weights: [
			0.4000010426721969, 0.2226363059187163, 0.0499957783673496,
			0.3273668818050926,
		],
	},
	{
		expectedReturn: 0.020815484417750028,
		volatility: 0.1208685669761723,
		weights: [
			0.4000010426704382, 0.2249913322512524, 0.0499957783615644,
			0.3250118554801514,
		],
	},
	{
		expectedReturn: 0.020881247929496794,
		volatility: 0.12087514957037511,
		weights: [
			0.4000010426708499, 0.2273463586952292, 0.0499957783611659,
			0.322656829036158,
		],
	},
	{
		expectedReturn: 0.020947011441074675,
		volatility: 0.1208829305923488,
		weights: [
			0.4000010426708525, 0.2297013851331534, 0.0499957783611744,
			0.3203018025982234,
		],
	},
	{
		expectedReturn: 0.021012774952648108,
		volatility: 0.1208919098106197,
		weights: [
			0.4000010426708519, 0.2320564115709239, 0.049995778361175,
			0.3179467761604528,
		],
	},
	{
		expectedReturn: 0.021078538464221783,
		volatility: 0.12090208695822419,
		weights: [
			0.4000010426708517, 0.2344114380087031, 0.0499957783611749,
			0.3155917497226737,
		],
	},
	{
		expectedReturn: 0.021144301975795476,
		volatility: 0.12091346173267752,
		weights: [
			0.400001042670852, 0.2367664644464824, 0.0499957783611751,
			0.3132367232848946,
		],
	},
	{
		expectedReturn: 0.021210065487369156,
		volatility: 0.12092603379601906,
		weights: [
			0.4000010426708519, 0.2391214908842618, 0.0499957783611751,
			0.3108816968471151,
		],
	},
	{
		expectedReturn: 0.021275828998942842,
		volatility: 0.12093980277486344,
		weights: [
			0.4000010426708521, 0.2414765173220412, 0.0499957783611751,
			0.3085266704093358,
		],
	},
	{
		expectedReturn: 0.02134159251051649,
		volatility: 0.12095476826045498,
		weights: [
			0.4000010426708514, 0.2438315437598202, 0.0499957783611748,
			0.3061716439715562,
		],
	},
	{
		expectedReturn: 0.021407356022090183,
		volatility: 0.12097092980872955,
		weights: [
			0.4000010426708516, 0.2461865701975996, 0.049995778361175,
			0.3038166175337768,
		],
	},
	{
		expectedReturn: 0.02147311953366387,
		volatility: 0.12098828694037864,
		weights: [
			0.4000010426708517, 0.248541596635379, 0.0499957783611749,
			0.3014615910959976,
		],
	},
	{
		expectedReturn: 0.02153888304523756,
		volatility: 0.12100683914092157,
		weights: [
			0.4000010426708518, 0.2508966230731586, 0.049995778361175,
			0.2991065646582182,
		],
	},
	{
		expectedReturn: 0.021604646556811248,
		volatility: 0.12102658586078088,
		weights: [
			0.400001042670852, 0.253251649510938, 0.049995778361175,
			0.2967515382204388,
		],
	},
	{
		expectedReturn: 0.02167041006838492,
		volatility: 0.12104752651536381,
		weights: [
			0.4000010426708519, 0.255606675948717, 0.0499957783611751,
			0.2943965117826598,
		],
	},
	{
		expectedReturn: 0.021736173579958593,
		volatility: 0.1210696604851482,
		weights: [
			0.4000010426708518, 0.2579617023864962, 0.0499957783611749,
			0.2920414853448805,
		],
	},
	{
		expectedReturn: 0.021801937091532293,
		volatility: 0.12109298711577433,
		weights: [
			0.4000010426708521, 0.2603167288242757, 0.0499957783611752,
			0.2896864589071012,
		],
	},
	{
		expectedReturn: 0.021867700603105975,
		volatility: 0.12111750571814023,
		weights: [
			0.400001042670852, 0.2626717552620551, 0.0499957783611751,
			0.2873314324693219,
		],
	},
	{
		expectedReturn: 0.021933464114679634,
		volatility: 0.12114321556850376,
		weights: [
			0.4000010426708514, 0.2650267816998344, 0.049995778361175,
			0.284976406031542,
		],
	},
	{
		expectedReturn: 0.02199922762625332,
		volatility: 0.12117011590858898,
		weights: [
			0.4000010426708516, 0.2673818081376138, 0.0499957783611749,
			0.2826213795937628,
		],
	},
	{
		expectedReturn: 0.02206499113782699,
		volatility: 0.12119820594569636,
		weights: [
			0.4000010426708514, 0.2697368345753928, 0.0499957783611749,
			0.2802663531559836,
		],
	},
	{
		expectedReturn: 0.022130754649400695,
		volatility: 0.12122748485281976,
		weights: [
			0.4000010426708519, 0.2720918610131726, 0.049995778361175,
			0.2779113267182043,
		],
	},
	{
		expectedReturn: 0.022196518160974364,
		volatility: 0.12125795176876633,
		weights: [
			0.4000010426708518, 0.2744468874509516, 0.049995778361175,
			0.2755563002804252,
		],
	},
	{
		expectedReturn: 0.022262281672548064,
		volatility: 0.12128960579828318,
		weights: [
			0.4000010426708518, 0.2768019138887314, 0.0499957783611751,
			0.2732012738426454,
		],
	},
	{
		expectedReturn: 0.02232804518412173,
		volatility: 0.12132244601218693,
		weights: [
			0.4000010426708517, 0.2791569403265104, 0.049995778361175,
			0.2708462474048664,
		],
	},
	{
		expectedReturn: 0.022393808695695412,
		volatility: 0.12135647144749948,
		weights: [
			0.400001042670852, 0.2815119667642895, 0.0499957783611751,
			0.2684912209670872,
		],
	},
	{
		expectedReturn: 0.02245957220726911,
		volatility: 0.12139168110758715,
		weights: [
			0.4000010426708521, 0.2838669932020691, 0.0499957783611751,
			0.2661361945293078,
		],
	},
	{
		expectedReturn: 0.022525335718842784,
		volatility: 0.12142807396230548,
		weights: [
			0.4000010426708518, 0.2862220196398485, 0.049995778361175,
			0.2637811680915286,
		],
	},
	{
		expectedReturn: 0.02259109923041644,
		volatility: 0.12146564894814804,
		weights: [
			0.4000010426708516, 0.2885770460776274, 0.0499957783611749,
			0.261426141653749,
		],
	},
	{
		expectedReturn: 0.022656862741990132,
		volatility: 0.12150440496840029,
		weights: [
			0.4000010426708515, 0.2909320725154071, 0.0499957783611749,
			0.2590711152159695,
		],
	},
	{
		expectedReturn: 0.022722626253563825,
		volatility: 0.12154434089329681,
		weights: [
			0.4000010426708518, 0.2932870989531865, 0.049995778361175,
			0.2567160887781902,
		],
	},
	{
		expectedReturn: 0.022788389765137505,
		volatility: 0.1215854555601841,
		weights: [
			0.4000010426708518, 0.2956421253909658, 0.049995778361175,
			0.2543610623404111,
		],
	},
	{
		expectedReturn: 0.022854153276711184,
		volatility: 0.12162774777368723,
		weights: [
			0.4000010426708517, 0.297997151828745, 0.049995778361175,
			0.2520060359026318,
		],
	},
	{
		expectedReturn: 0.022919916788284863,
		volatility: 0.12167121630588104,
		weights: [
			0.4000010426708518, 0.3003521782665242, 0.0499957783611749,
			0.2496510094648525,
		],
	},
	{
		expectedReturn: 0.022985680299858556,
		volatility: 0.12171585989646531,
		weights: [
			0.400001042670852, 0.3027072047043037, 0.0499957783611751,
			0.2472959830270732,
		],
	},
	{
		expectedReturn: 0.02305144381143224,
		volatility: 0.12176167725294389,
		weights: [
			0.4000010426708519, 0.3050622311420832, 0.0499957783611751,
			0.2449409565892938,
		],
	},
	{
		expectedReturn: 0.023117207323005932,
		volatility: 0.12180866705080885,
		weights: [
			0.400001042670852, 0.3074172575798627, 0.0499957783611751,
			0.2425859301515144,
		],
	},
	{
		expectedReturn: 0.023182970834579594,
		volatility: 0.12185682793372782,
		weights: [
			0.4000010426708518, 0.3097722840176418, 0.0499957783611749,
			0.240230903713735,
		],
	},
	{
		expectedReturn: 0.023248734346153262,
		volatility: 0.12190615851373603,
		weights: [
			0.4000010426708518, 0.3121273104554208, 0.0499957783611749,
			0.2378758772759556,
		],
	},
	{
		expectedReturn: 0.02331449785772696,
		volatility: 0.12195665737143171,
		weights: [
			0.4000010426708518, 0.3144823368932006, 0.049995778361175,
			0.235520850838176,
		],
	},
	{
		expectedReturn: 0.023380261369300645,
		volatility: 0.12200832305617546,
		weights: [
			0.4000010426708519, 0.3168373633309799, 0.049995778361175,
			0.233165824400397,
		],
	},
	{
		expectedReturn: 0.02344602488087433,
		volatility: 0.12206115408629394,
		weights: [
			0.4000010426708519, 0.3191923897687595, 0.049995778361175,
			0.2308107979626174,
		],
	},
	{
		expectedReturn: 0.023511788392448,
		volatility: 0.12211514894928689,
		weights: [
			0.4000010426708518, 0.3215474162065385, 0.0499957783611749,
			0.2284557715248383,
		],
	},
	{
		expectedReturn: 0.02357755190402169,
		volatility: 0.1221703061020382,
		weights: [
			0.4000010426708516, 0.3239024426443181, 0.0499957783611749,
			0.2261007450870588,
		],
	},
	{
		expectedReturn: 0.023643315415595365,
		volatility: 0.1222266239710299,
		weights: [
			0.4000010426708518, 0.326257469082097, 0.0499957783611751,
			0.2237457186492798,
		],
	},
	{
		expectedReturn: 0.02370907892716906,
		volatility: 0.12228410095256051,
		weights: [
			0.4000010426708519, 0.3286124955198766, 0.049995778361175,
			0.2213906922115007,
		],
	},
	{
		expectedReturn: 0.023774842438742724,
		volatility: 0.12234273541296628,
		weights: [
			0.4000010426708516, 0.3309675219576558, 0.0499957783611749,
			0.2190356657737209,
		],
	},
	{
		expectedReturn: 0.023840605950316403,
		volatility: 0.12240252568884677,
		weights: [
			0.4000010426708515, 0.3333225483954352, 0.0499957783611748,
			0.2166806393359415,
		],
	},
	{
		expectedReturn: 0.023906369461890082,
		volatility: 0.12246347008729237,
		weights: [
			0.4000010426708514, 0.3356775748332146, 0.0499957783611748,
			0.214325612898162,
		],
	},
	{
		expectedReturn: 0.023972132973463768,
		volatility: 0.12252556688611639,
		weights: [
			0.4000010426708519, 0.3380326012709936, 0.049995778361175,
			0.2119705864603832,
		],
	},
	{
		expectedReturn: 0.024037896485037454,
		volatility: 0.1225888143340893,
		weights: [
			0.400001042670852, 0.340387627708773, 0.0499957783611751,
			0.2096155600226035,
		],
	},
	{
		expectedReturn: 0.024103659996611133,
		volatility: 0.12265321065117737,
		weights: [
			0.4000010426708517, 0.3427426541465524, 0.049995778361175,
			0.2072605335848243,
		],
	},
	{
		expectedReturn: 0.024169423508184816,
		volatility: 0.12271875402878324,
		weights: [
			0.4000010426708518, 0.3450976805843316, 0.049995778361175,
			0.2049055071470451,
		],
	},
	{
		expectedReturn: 0.024235187019758523,
		volatility: 0.12278544262999001,
		weights: [
			0.4000010426708521, 0.3474527070221116, 0.049995778361175,
			0.2025504807092654,
		],
	},
	{
		expectedReturn: 0.0243009505313322,
		volatility: 0.12285327458980799,
		weights: [
			0.4000010426708521, 0.3498077334598907, 0.0499957783611751,
			0.2001954542714864,
		],
	},
	{
		expectedReturn: 0.02436671404290587,
		volatility: 0.12292224801542462,
		weights: [
			0.4000010426708516, 0.3521627598976701, 0.049995778361175,
			0.1978404278337069,
		],
	},
	{
		expectedReturn: 0.024432477554479533,
		volatility: 0.12299236098645719,
		weights: [
			0.4000010426708518, 0.3545177863354488, 0.0499957783611751,
			0.1954854013959274,
		],
	},
	{
		expectedReturn: 0.024498241066053215,
		volatility: 0.1230636115552078,
		weights: [
			0.4000010426708516, 0.3568728127732284, 0.0499957783611749,
			0.1931303749581478,
		],
	},
	{
		expectedReturn: 0.024564004577626912,
		volatility: 0.12313599774692137,
		weights: [
			0.4000010426708518, 0.359227839211008, 0.0499957783611749,
			0.1907753485203688,
		],
	},
	{
		expectedReturn: 0.024629768089200598,
		volatility: 0.1232095175600457,
		weights: [
			0.4000010426708522, 0.361582865648787, 0.0499957783611751,
			0.1884203220825898,
		],
	},
	{
		expectedReturn: 0.024695531600774277,
		volatility: 0.12328416896649462,
		weights: [
			0.400001042670852, 0.3639378920865666, 0.049995778361175,
			0.18606529564481,
		],
	},
	{
		expectedReturn: 0.024761295112347953,
		volatility: 0.12335994991191358,
		weights: [
			0.4000010426708518, 0.3662929185243458, 0.0499957783611749,
			0.1837102692070309,
		],
	},
	{
		expectedReturn: 0.02482705862392164,
		volatility: 0.12343685831594679,
		weights: [
			0.400001042670852, 0.368647944962125, 0.0499957783611751,
			0.181355242769252,
		],
	},
	{
		expectedReturn: 0.02489282213549532,
		volatility: 0.12351489207250693,
		weights: [
			0.4000010426708518, 0.3710029713999044, 0.0499957783611752,
			0.1790002163314724,
		],
	},
	{
		expectedReturn: 0.024958585647069008,
		volatility: 0.12359404905004762,
		weights: [
			0.400001042670852, 0.3733579978376838, 0.0499957783611751,
			0.176645189893693,
		],
	},
	{
		expectedReturn: 0.025024349158642666,
		volatility: 0.12367432709183711,
		weights: [
			0.4000010426708517, 0.375713024275463, 0.0499957783611749,
			0.174290163455913,
		],
	},
	{
		expectedReturn: 0.025090112670216363,
		volatility: 0.12375572401623511,
		weights: [
			0.4000010426708518, 0.3780680507132427, 0.0499957783611749,
			0.1719351370181338,
		],
	},
	{
		expectedReturn: 0.025155876181790035,
		volatility: 0.12383823761696959,
		weights: [
			0.4000010426708518, 0.3804230771510216, 0.049995778361175,
			0.1695801105803552,
		],
	},
	{
		expectedReturn: 0.02522163969336373,
		volatility: 0.12392186566341763,
		weights: [
			0.4000010426708518, 0.3827781035888013, 0.049995778361175,
			0.1672250841425758,
		],
	},
	{
		expectedReturn: 0.02528740320493741,
		volatility: 0.12400660590088673,
		weights: [
			0.4000010426708518, 0.3851331300265806, 0.0499957783611749,
			0.1648700577047962,
		],
	},
	{
		expectedReturn: 0.0253531667165111,
		volatility: 0.12409245605089866,
		weights: [
			0.400001042670852, 0.38748815646436, 0.0499957783611751,
			0.1625150312670168,
		],
	},
	{
		expectedReturn: 0.025418930228084773,
		volatility: 0.1241794138114736,
		weights: [
			0.4000010426708517, 0.3898431829021394, 0.0499957783611749,
			0.1601600048292375,
		],
	},
	{
		expectedReturn: 0.025484693739658462,
		volatility: 0.12426747685741772,
		weights: [
			0.4000010426708518, 0.3921982093399189, 0.049995778361175,
			0.157804978391458,
		],
	},
	{
		expectedReturn: 0.025550457251232145,
		volatility: 0.12435664284061033,
		weights: [
			0.400001042670852, 0.394553235777698, 0.049995778361175,
			0.155449951953679,
		],
	},
	{
		expectedReturn: 0.025616220762805803,
		volatility: 0.12444690939029385,
		weights: [
			0.4000010426708517, 0.396908262215477, 0.0499957783611751,
			0.1530949255158992,
		],
	},
	{
		expectedReturn: 0.025681832798599587,
		volatility: 0.12453858242816401,
		weights: [
			0.3999985424148088, 0.3992579416930262, 0.0499980523193264,
			0.1507454838324935,
		],
	},
	{
		expectedReturn: 0.025748551286040554,
		volatility: 0.12509584758936942,
		weights: [
			0.4000005276455872, 0.3999968034841279, 0.0534813679889564,
			0.1465212967675241,
		],
	},
	{
		expectedReturn: 0.025814174785194162,
		volatility: 0.1258700592090004,
		weights: [
			0.3999980079194969, 0.3999977256705028, 0.0584446451897192,
			0.141559621372261,
		],
	},
	{
		expectedReturn: 0.025879942417328182,
		volatility: 0.12666996804998557,
		weights: [
			0.3999979744931798, 0.3999976516370028, 0.0634184975959148,
			0.1365858763909094,
		],
	},
	{
		expectedReturn: 0.025945705932073883,
		volatility: 0.12749362531529057,
		weights: [
			0.3999979745867466, 0.3999976516312953, 0.0683918494897712,
			0.1316125244090656,
		],
	},
	{
		expectedReturn: 0.026011469443507997,
		volatility: 0.12834065089825505,
		weights: [
			0.3999979745878364, 0.3999976516337853, 0.0733652012062308,
			0.1266391726890278,
		],
	},
	{
		expectedReturn: 0.02607723295508163,
		volatility: 0.12921058525902016,
		weights: [
			0.399997974587833, 0.3999976516337847, 0.0783385529395711,
			0.1216658209556917,
		],
	},
	{
		expectedReturn: 0.026142996466655317,
		volatility: 0.13010296885656086,
		weights: [
			0.3999979745878325, 0.3999976516337848, 0.0833119046729111,
			0.1166924692223519,
		],
	},
	{
		expectedReturn: 0.026208759978229003,
		volatility: 0.13101734297384446,
		weights: [
			0.3999979745878328, 0.3999976516337848, 0.0882852564062506,
			0.1117191174890121,
		],
	},
	{
		expectedReturn: 0.026274523489802668,
		volatility: 0.13195325045970926,
		weights: [
			0.3999979745878327, 0.3999976516337846, 0.0932586081395898,
			0.1067457657556731,
		],
	},
	{
		expectedReturn: 0.026340287001376347,
		volatility: 0.13291023642354124,
		weights: [
			0.3999979745878324, 0.3999976516337846, 0.0982319598729296,
			0.1017724140223335,
		],
	},
	{
		expectedReturn: 0.02640605051295004,
		volatility: 0.13388784888018226,
		weights: [
			0.3999979745878326, 0.3999976516337848, 0.103205311606269,
			0.0967990622889939,
		],
	},
	{
		expectedReturn: 0.026471814024523713,
		volatility: 0.13488563934565256,
		weights: [
			0.3999979745878332, 0.3999976516337843, 0.1081786633396084,
			0.0918257105556547,
		],
	},
	{
		expectedReturn: 0.026537577536097402,
		volatility: 0.13590316338456015,
		weights: [
			0.3999979745878327, 0.3999976516337848, 0.1131520150729479,
			0.0868523588223149,
		],
	},
	{
		expectedReturn: 0.026603341047671075,
		volatility: 0.13693998111032982,
		weights: [
			0.399997974587833, 0.3999976516337843, 0.1181253668062877,
			0.0818790070889752,
		],
	},
	{
		expectedReturn: 0.026669104559244757,
		volatility: 0.13799565763960311,
		weights: [
			0.3999979745878327, 0.3999976516337845, 0.1230987185396267,
			0.0769056553556363,
		],
	},
	{
		expectedReturn: 0.026734868070818436,
		volatility: 0.1390697635023433,
		weights: [
			0.3999979745878328, 0.3999976516337844, 0.1280720702729664,
			0.0719323036222964,
		],
	},
	{
		expectedReturn: 0.02680063158239212,
		volatility: 0.1401618750093294,
		weights: [
			0.3999979745878326, 0.3999976516337847, 0.1330454220063056,
			0.0669589518889573,
		],
	},
	{
		expectedReturn: 0.0268663950939658,
		volatility: 0.14127157457884476,
		weights: [
			0.3999979745878332, 0.3999976516337842, 0.1380187737396452,
			0.0619856001556177,
		],
	},
	{
		expectedReturn: 0.02693215860553948,
		volatility: 0.14239845102445245,
		weights: [
			0.3999979745878327, 0.3999976516337844, 0.1429921254729846,
			0.0570122484222784,
		],
	},
	{
		expectedReturn: 0.026997922117113174,
		volatility: 0.14354209980581562,
		weights: [
			0.3999979745878328, 0.3999976516337848, 0.1479654772063242,
			0.0520388966889385,
		],
	},
];
const DataGridStackItemWidget = () => {
	const navigate = useNavigate();
	return (
		<Stack
			direction="row"
			spacing={5}
			sx={{
				width: '100%',
				height: '100%',
				justifyContent: 'center',
				alignItems: 'center',
			}}>
			<Stack
				spacing={2}
				sx={{
					justifyContent: 'center',
					alignItems: 'flex-start',
				}}>
				<Stack spacing={0}>
					<Box
						sx={{
							fontWeight: 'bold',
							color: colors.text.sub1,
							textAlign: 'left', // 좌측 정렬
							maxWidth: '100%', // 최대 너비 설정
							fontSize: '50px',
							lineHeight: '1', // 줄 간격 설정
							whiteSpace: 'nowrap',
							letterSpacing: '-2px',
						}}>
						당신의 포트폴리오에
					</Box>
					<Box
						sx={{
							fontWeight: 'bold',
							color: colors.main.primary500,
							textAlign: 'left', // 좌측 정렬
							maxWidth: '100%', // 최대 너비 설정
							fontSize: '50px',
							lineHeight: '1.5', // 줄 간격 설정
							whiteSpace: 'nowrap',
							letterSpacing: '-2px',
						}}>
						위험이 없도록
					</Box>
					<Box
						sx={{
							fontWeight: 'bold',
							color: colors.text.sub1,
							textAlign: 'left', // 좌측 정렬
							maxWidth: '100%', // 최대 너비 설정
							fontSize: '25px',
							lineHeight: '1.5', // 줄 간격 설정
							whiteSpace: 'nowrap',
							letterSpacing: '-2px',
						}}>
						최적의 포트폴리오를 확인하세요
					</Box>
				</Stack>
				{/* 포트폴리오 제작 버튼 */}
				<CustomButton
					variant="contained"
					sx={{
						width: '180px',
						height: '45px',
						backgroundColor: colors.main.primary400,
						border: 'none',
						color: '#FFFFFF',
						padding: '10px 20px',
						fontSize: '16px',
						fontWeight: 'bold',
						borderRadius: '5px',
						textAlign: 'left', // 버튼 텍스트 좌측 정렬
						'&:hover': {
							backgroundColor: colors.main.primary200,
						},
						marginBottom: '40px',
					}}
					onClick={() => navigate('/login')}
					text={'포트폴리오 제작'}></CustomButton>
			</Stack>
			<Box
				sx={{
					fontSize: '12px',
					height: '400px',
					width: '650px',
				}}>
				<ResponsiveContainer>
					<LineChart data={efficientFrontierData}>
						<XAxis
							dataKey="volatility"
							label={{
								value: '변동성 (위험도)',
								position: 'insideBottom',
								offset: -15,
							}}
							type="number"
							domain={['auto', 'auto']}
						/>
						<YAxis
							dataKey="expectedReturn"
							label={{
								value: '기대 수익률',
								angle: -90,
								position: 'insideLeft',
								offset: -10,
							}}
							type="number"
							domain={['auto', 'auto']}
						/>
						<Tooltip />
						<Legend verticalAlign="top" align="left" height={36} />
						<Line
							name="기대 수익률"
							dataKey="expectedReturn"
							type="monotone"
							stroke={colors.main.primary600}
							dot={false}
						/>
						{/* <Line
							name="S&P 500"
							dataKey="sp500Returns"
							type="monotone"
							stroke={colors.main.primary800}
							dot={true}
						/> */}
					</LineChart>
				</ResponsiveContainer>
			</Box>
		</Stack>
	);
};

export default DataGridStackItemWidget;
